import { WistiaDialog, YoutubeDialog } from 'global/simple-dialog.src';

(function (callback) {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
})(function () {
    [].forEach.call(document.querySelectorAll('[data-wistia], [data-youtube]'), function (elem) {
        if (elem.getAttribute('data-wistia')) {
            var dialogElem = document.getElementById(elem.getAttribute('data-wistia')),
                dialog = new WistiaDialog(dialogElem);
        } else {
            var dialogElem = document.getElementById(elem.getAttribute('data-youtube')),
                dialog = new YoutubeDialog(dialogElem);
        }

        elem.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            dialog.show();
        });
    });
});
