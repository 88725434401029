(function () {
    function assign(target, firstSource) {
        if (target === undefined || target === null) {
            throw new TypeError('Cannot convert first argument to object');
        }

        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
            var nextSource = arguments[i];
            if (nextSource === undefined || nextSource === null) {
                continue;
            }

            var keysArray = Object.keys(Object(nextSource));
            for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
                var nextKey = keysArray[nextIndex];
                var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
                if (desc !== undefined && desc.enumerable) {
                    to[nextKey] = nextSource[nextKey];
                }
            }
        }
        return to;
    }

    function polyfill() {
        if (!Object.assign) {
            Object.defineProperty(Object, 'assign', {
                enumerable: false,
                configurable: true,
                writable: true,
                value: assign,
            });
        }
    }
    polyfill();
})();

export function SimpleDialog(elem, opts) {
    var defaultOpts = {
        onOpen: false,
    };
    this.opts = {};
    this.elem = elem;

    var self = this;

    Object.assign(this.opts, defaultOpts, opts);

    document.addEventListener('keydown', function (e) {
        if (e.keyCode == 27) {
            self.hide();
        }
    });

    if (this.elem instanceof HTMLElement !== true) {
        throw new Error('Dialog element not found');
    }

    var closeButton = this.elem.querySelector('.simple-dialog-close');

    if (closeButton instanceof HTMLElement === true) {
        closeButton.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            self.hide();
        });
    }

    try {
        this.elem.querySelector('.simple-dialog-body').addEventListener('click', function (e) {
            e.stopPropagation();
        });
    } catch (e) {
        throw new Error('Cannot find dialog body element');
    }
    this.elem.addEventListener('click', function (e) {
        e.stopPropagation();
        self.hide();
    });

    return this;
}

SimpleDialog.prototype.show = function () {
    this.elem.classList.add('simple-dialog-visible');
    if (typeof this.opts.onOpen === 'function') {
        this.opts.onOpen(this);
    }
};
SimpleDialog.prototype.hide = function () {
    this.elem.classList.remove('simple-dialog-visible');
    if (window.location.hash === '#' + this.elem.id) {
        var tmpHash = 'tmp' + Date.now();
        window.location.hash = '#' + tmpHash;
        try {
            history.replaceState(undefined, undefined, window.location.href.replace('#' + tmpHash, ''));
        } catch (e) {}
    }

    if (typeof this.opts.onClose === 'function') {
        this.opts.onClose(this);
    }
};

export function YoutubeDialog(elem, opts) {
    if (!elem) {
        return;
    }
    var newOpts = {};
    var player;
    var playerElem = elem.querySelector('.video-player');
    var videoId = playerElem.getAttribute('data-video-id');
    var videoAutoplay = playerElem.getAttribute('data-video-autoplay');
    var playerQueue = [];
    var initialized = false;
    var youTubeDialog = {};

    Object.assign(newOpts, opts, {
        onOpen: function (dialog) {
            if (initialized === false) {
                youTubeApi.init();

                youTubeApi.enqueue(function (YT) {
                    player = new YT.Player(playerElem, {
                        height: '360',
                        width: '640',
                        videoId: videoId,
                        events: {
                            onReady: function (event) {
                                playerQueue.forEach(function (f) {
                                    f(player);
                                });
                                playerQueue = [];
                            },
                        },
                    });
                });

                initialized = true;
            }

            if (typeof player !== 'undefined' && typeof player.playVideo === 'function') {
                player.seekTo(0);
                if (videoAutoplay) {
                    player.playVideo();
                } else {
                    player.stopVideo();
                }
            } else {
                playerQueue.push(function (player) {
                    player.seekTo(0);
                    if (videoAutoplay) {
                        player.playVideo();
                    } else {
                        player.stopVideo();
                    }
                });
            }
            if (opts && typeof opts.onOpen == 'function') {
                opts.onOpen();
            }
        },
        onClose: function (dialog) {
            if (typeof player !== 'undefined' && typeof player.stopVideo === 'function') {
                player.stopVideo();
            } else {
                playerQueue.push(function (player) {
                    player.stopVideo();
                });
            }
            if (opts && typeof opts.onClose == 'function') {
                opts.onClose();
            }
        },
    });

    youTubeDialog = new SimpleDialog(elem, newOpts);
    Object.assign(youTubeDialog, {
        playerExecute: function (f) {
            if (typeof player !== 'undefined') {
                f(player);
            } else {
                playerQueue.push(function (player) {
                    f(player);
                });
            }
        },
    });
    return youTubeDialog;
}

var youTubeApi = (function () {
    var initialized = false;
    var queue = [];
    var apiReady = false;

    return {
        isInitialized: function () {
            return initialized;
        },
        init: function () {
            var apiScriptTag = document.createElement('script');
            var firstScriptTag = document.getElementsByTagName('script')[0];

            if (this.isInitialized !== true) {
                apiScriptTag.src = 'https://www.youtube.com/iframe_api';
                apiScriptTag.onload = function (script) {
                    YT.ready(function () {
                        apiReady = true;
                        queue.forEach(function (f) {
                            f(YT);
                        });
                        queue = [];
                    });
                };
                firstScriptTag.parentNode.insertBefore(apiScriptTag, firstScriptTag);
                initialized = true;
            }
        },
        enqueue: function (f) {
            if (apiReady) {
                f(YT);
            } else {
                queue.push(f);
            }
        },
        isReady: function () {
            return apiReady;
        },
    };
})();

export function WistiaDialog(elem, opts) {
    if (!elem) {
        return;
    }
    var newOpts = {};
    var player;
    var wistia_embed;
    var playerElem = elem.querySelector('.video-player');
    var videoId = playerElem.getAttribute('data-video-id');
    var videoAutoplay = playerElem.getAttribute('data-video-autoplay');
    var initialized = false;
    var WistiaDialog = {};
    var videoFrame;

    var initializePlayer = function (dialog, videoAutoplay) {
        wistia_embed = document.createElement('div');
        wistia_embed.classList.add('wistia_embed');
        wistia_embed.classList.add('wistia_async_' + videoId);

        playerElem.appendChild(wistia_embed);

        window._wq = window._wq || [];
        _wq.push({
            id: videoId,
            onReady: function (video) {
                player = video;

                if (typeof player !== 'undefined' && videoAutoplay === 'true') {
                    if (dialog.elem.classList.contains('simple-dialog-visible') === true) {
                        player.play();
                    }
                }
                video.bind('secondchange', function (s) {
                    videoFrame = s;
                });

                if (videoFrame) {
                    player.time(videoFrame);
                }
            },
        });
    };

    Object.assign(newOpts, opts, {
        onOpen: function (dialog) {
            if (initialized === false) {
                WistiaApi.init();
                initialized = true;
            }
            if (!player) {
                initializePlayer(dialog, videoAutoplay);
            } else {
                if (videoAutoplay === 'true') {
                    player.time(0);
                    player.play();
                }
            }
            if (opts && typeof opts.onOpen == 'function') {
                opts.onOpen();
            }
        },
        onClose: function (dialog) {
            if (typeof player !== 'undefined' && player !== null) {
                player.remove();
                player = null;
            }
            if (opts && typeof opts.onClose == 'function') {
                opts.onClose();
            }
        },
    });

    WistiaDialog = new SimpleDialog(elem, newOpts);
    Object.assign(WistiaDialog, {
        playerExecute: function (f) {
            if (typeof player !== 'undefined') {
                f(player);
            } else {
                playerQueue.push(function (player) {
                    f(player);
                });
            }
        },
    });

    if (videoAutoplay && elem.className.indexOf('simple-dialog-visible') !== -1) {
        if (initialized === false) {
            WistiaApi.init();
            initialized = true;
        }
        if (!player) {
            initializePlayer(WistiaDialog, videoAutoplay);
        } else {
            player.time(0);
            player.play();
        }
    }
    return WistiaDialog;
}

var WistiaApi = (function () {
    var initialized = false;
    var queue = [];
    var apiReady = false;

    return {
        isInitialized: function () {
            return initialized;
        },
        init: function () {
            var apiScriptTag = document.createElement('script');
            var firstScriptTag = document.getElementsByTagName('script')[0];
            window._wq = window._wq || [];

            if (this.isInitialized !== true) {
                apiScriptTag.src = 'https://fast.wistia.com/assets/external/E-v1.js';
                apiScriptTag.setAttribute('async', 'async');

                apiScriptTag.onload = function (script) {
                    apiReady = true;
                };
                firstScriptTag.parentNode.insertBefore(apiScriptTag, firstScriptTag);
                initialized = true;
            }
        },
        isReady: function () {
            return apiReady;
        },
    };
})();
